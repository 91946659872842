import React from 'react'
import { Card } from 'antd';
const { Meta } = Card;

export default function CardComponent(props) {
  return (
    <div className='cardClass'> 
      <Card 
        className='cardStyle'
        style={{ width: 240 }}
        cover={<img src={props.image} />}
        >
          <span className='card_price'>{props.price}€</span>
        <Meta className='palavra' title={props.title} description={props.type} />
        <p>{props.description}</p>
      </Card>
      </div>
  )
}





