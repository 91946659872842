import './App.css';
import React,{useEffect,useState} from 'react';
import Navbar from './components/Navbar';
import Card from './components/CardComponent';


function App() {

  const [products, setProducts] = useState([])


  useEffect(()=>{
    fetch('https://kaiodeodato.github.io/API_AI_STORE/myData.json')
    .then(res=>res.json())
    .then(json=>setProducts(json.results))

  },[])


  return (
    <div className="App">
      <Navbar logo='Ford' />
      <div className='boxElementos'>
        {products.map(item => (
          <Card
          key={item.id}
          image={item.image}
          title={item.title} 
          description={item.description}
          price={item.price}
          type={item.type}
        />
        )
        )}
      </div>
    

     
    </div>
  );
}

export default App;


