import React from 'react'

export default function Navbar(props) {
  return (
    <div className='containerNavbar'>
        <h3>{props.logo}</h3>
        <nav>
            <span>Home</span>
            <span>Store</span>
            <span>Contact</span>
        </nav>
    </div>
  )
}
